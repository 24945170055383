import React from "react";
import Container from "./Container";
import { faqs } from "./FaqConstants";

const FAQ = () => {
  const AccordionItem = ({ faq, index, expandedIndex, toggleAccordion }) => {
    const isExpanded = expandedIndex === index;

    return (
      <div className="flex flex-col border-t border-solid max-md:max-w-full">
        <div
          className="flex justify-between items-center px-5 py-6 cursor-pointer max-md:flex-wrap max-md:px-3"
          onClick={() => toggleAccordion(index)}
        >
          <div className="text-md font-medium leading-7 text-gray-900 max-md:w-full max-md:mb-2">
            {faq.question}
          </div>
          <div className="flex-shrink-0">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cd3d0c0517ae408eafad52cee2d3cf2630d7077ff7e06715c667799d8426ebe3?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
              className="w-6 aspect-square"
              alt="expand-icon"
            />
          </div>
        </div>
        {isExpanded && (
          <div className="text-base leading-6 text-gray-600 px-5 pb-6 max-md:px-3">
            {faq.answer}
          </div>
        )}
      </div>
    );
  };

  const [expandedIndex, setExpandedIndex] = React.useState(null);

  const toggleAccordion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section id="faqs" className="py-8 bg-white">
      <Container>
        <div className="pt-12">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[45%] max-md:w-full max-md:ml-0">
              <div className="flex flex-col grow self-stretch pb-20 max-md:pb-10 max-md:mt-10 max-md:max-w-full">
                <div className="text-4xl font-medium leading-12 text-gray-800 px-5 max-md:text-3xl max-md:leading-10 max-md:px-3">
                  Frequently Asked Questions
                </div>
                <div className="mt-4 text-base leading-6 text-gray-600 px-5 max-md:text-sm max-md:leading-5 max-md:mt-2 max-md:px-3">
                  Quick answers to common questions. If you need more help, our
                  support team is just an email away!
                </div>
              </div>
            </div>
            <div className="flex flex-col w-[55%] max-md:w-full max-md:ml-0">
              <div className="flex flex-col grow self-stretch py-px border-b border-solid max-md:border-0 max-md:mt-0">
                {faqs.map((faq, index) => (
                  <AccordionItem
                    key={index}
                    faq={faq}
                    index={index}
                    expandedIndex={expandedIndex}
                    toggleAccordion={toggleAccordion}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FAQ;

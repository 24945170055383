import React from "react";
import Container from "./Container";

const KeyFeatures = () => {
  return (
    <section id="features" className="py-10 bg-white">
      <Container>
        <div className="flex flex-col px-2.5 pt-10">
          <div className="flex flex-col justify-between self-center px-6 text-center max-md:px-5 max-md:max-w-full">
            <div className="text-5xl font-medium leading-10 text-gray-900 max-md:max-w-full max-md:text-4xl">
              Tailored for your success
            </div>
            <div className="self-center mt-4 mb-20 text-base leading-6 text-gray-600 max-md:max-w-full">
              Our platform empowers your team to excel and thrive in today's
              dynamic business landscape
            </div>
          </div>
          <div className="justify-between p-4 bg-gray-100 rounded-3xl border border-solid">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow justify-center pb-28 max-md:mt-10 max-md:max-w-full">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2232183320dc04f0cc5a5c4ea182222ba5cb59177716b4de9d094c7c3d10e5f7?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2232183320dc04f0cc5a5c4ea182222ba5cb59177716b4de9d094c7c3d10e5f7?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2232183320dc04f0cc5a5c4ea182222ba5cb59177716b4de9d094c7c3d10e5f7?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2232183320dc04f0cc5a5c4ea182222ba5cb59177716b4de9d094c7c3d10e5f7?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2232183320dc04f0cc5a5c4ea182222ba5cb59177716b4de9d094c7c3d10e5f7?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2232183320dc04f0cc5a5c4ea182222ba5cb59177716b4de9d094c7c3d10e5f7?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2232183320dc04f0cc5a5c4ea182222ba5cb59177716b4de9d094c7c3d10e5f7?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2232183320dc04f0cc5a5c4ea182222ba5cb59177716b4de9d094c7c3d10e5f7?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="w-full aspect-[1.67] max-md:max-w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 border rounded-lg max-md:ml-0 max-md:w-full bg-transparent">
                <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                  {/* First feature block */}
                  <div className="flex flex-col w-6/12 max-md:w-full">
                    <div
                      className="flex flex-col grow p-8 rounded-2xl max-md:px-5 border-gray-300 border-solid"
                      style={{
                        transition: "background-color 0.3s",
                        backgroundColor: "#FFFFFF", // Default hover state for the first block
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#FFFFFF";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    >
                      <div className="flex flex-col justify-center items-start py-2 rounded border-solid max-md:pr-5">
                        <div className="border p-1 border-gray-300 border-solid rounded">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/47561b6b25f079e004a9de136c04e00a1f6c488a792bb9a3ecfbffa28bfa8921?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                            className="w-6 aspect-square"
                            alt="AI Skill Assessment"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mt-6">
                        <div className="justify-center text-md font-bold leading-7 text-gray-900">
                          AI Skill Assessment
                        </div>
                        <div className="justify-center mt-3 text-sm leading-6 text-gray-600">
                          Accurate measurement of employee skills to identify
                          gaps and opportunities.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Second feature block */}
                  <div className="flex flex-col ml-5 max-md:ml-0 w-6/12 max-md:w-full">
                    <div
                      className="flex flex-col grow p-8 rounded-2xl max-md:px-5 border-gray-300 border-solid"
                      style={{ transition: "background-color 0.3s" }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#FFFFFF";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    >
                      <div className="flex flex-col justify-center items-start py-2 rounded border-solid max-md:pr-5">
                        <div className="border p-1 border-gray-300 border-solid rounded">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/12156d964ae80e211b531cfb9b8a2228f2f61c70b6bb4c971b5416f68c3602b9?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                            className="w-full aspect-square"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mt-6">
                        <div className="justify-center text-md font-bold leading-7 text-gray-900">
                          Personalized Learning
                        </div>
                        <div className="justify-center mt-3 text-sm leading-6 text-gray-600">
                          Tailored development plans to help employees grow and
                          excel in their roles.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Second row */}
                <div className="flex gap-5 max-md:flex-col max-md:gap-0 mt-2">
                  {/* Third feature block */}
                  <div className="flex flex-col w-6/12 max-md:w-full">
                    <div
                      className="flex flex-col grow p-8 rounded-2xl max-md:px-5 border-gray-300 border-solid"
                      style={{ transition: "background-color 0.3s" }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#FFFFFF";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    >
                      <div className="flex flex-col justify-center items-start py-2 rounded border-solid max-md:pr-5">
                        <div className="border p-1 border-gray-300 border-solid rounded">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/235b2fba04a1b40b9ebfea5636c0175c64d35de79bb71871d05984436d6e714d?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                            className="w-6 aspect-square"
                            alt="Real-World Projects"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mt-6">
                        <div className="justify-center text-md font-bold leading-7 text-gray-900">
                          Real-World Projects
                        </div>
                        <div className="justify-center mt-3 text-sm leading-6 text-gray-600">
                          Engage your team in hands-on projects that provide
                          practical experience for future challenges.
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Fourth feature block */}
                  <div className="flex flex-col ml-5 max-md:ml-0 w-6/12 max-md:w-full">
                    <div
                      className="flex flex-col grow p-8 rounded-2xl max-md:px-5 border-gray-300 border-solid"
                      style={{ transition: "background-color 0.3s" }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "#FFFFFF";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "transparent";
                      }}
                    >
                      <div className="flex flex-col justify-center items-start py-2 rounded border-solid max-md:pr-5">
                        <div className="border p-1 border-gray-300 border-solid rounded">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cbfd2834767d31c2b163039c32b209beeb2594f291bdda09ea7c808ac1ee74da?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                            className="w-6 aspect-square"
                            alt="Actionable Insights"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col mt-6">
                        <div className="justify-center text-md font-bold leading-7 text-gray-900">
                          Actionable Insights
                        </div>
                        <div className="justify-center mt-3 text-sm leading-6 text-gray-600">
                          Detailed analytics and reports to guide your talent
                          management strategies.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default KeyFeatures;

import React from "react";

const Button = ({ href, text }) => {
  return (
    <a
      href={href}
      className="justify-center self-start px-4 py-2 text-base leading-6 text-white bg-blue-600 rounded-lg max-md:mt-3 hover:bg-blue-700"
    >
      {text}
    </a>
  );
};

export default Button;

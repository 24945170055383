// constants.js

export const faqs = [
  {
    question: "Is WizTap suitable for individuals at different skill levels?",
    answer:
      "Absolutely! Whether you're a novice or a seasoned professional, WizTap caters to users of all proficiency levels, offering tailored resources and opportunities for personalized growth and development.",
  },
  {
    question: "How does WizTap customize learning experiences for its users?",
    answer:
      "WizTap employs AI-driven assessments to evaluate users' skills and interests, enabling the platform to create personalized learning paths and real-world projects that align with their individual aspirations and requirements.",
  },
  {
    question:
      "How does WizTap provide data-driven insights into my organization's talent landscape?",
    answer:
      "With WizTap's data analytics capabilities, organizations gain valuable insights into their talent landscape, enabling them to track employee progress, identify trends, and make data-driven decisions to drive strategic initiatives effectively.",
  },
  {
    question:
      "What measures does WizTap take to ensure industry relevance in its skill development programs?",
    answer:
      "WizTap collaborates with industry experts and partners to ensure that its skill development programs are relevant to current industry needs and standards, offering industry-relevant projects and challenges to enhance employability.",
  },
  {
    question:
      "What types of skill assessments does WizTap offer, and how can they benefit my organization?",
    answer:
      "WizTap offers comprehensive skill assessments that evaluate employees' capabilities and areas for improvement regarding their training and development initiatives.",
  },
];

import React from "react";
import Container from "./Container";
import Button from "./Button";
import KeyfeatureImage from "../Assets/Images/KeyfeatureImage.png";

const keyfeature2 = () => {
  return (
    <section id="keyfeature1" className=" bg-white">
      <Container>
        <div className="py-14">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col self-stretch px-5 my-auto text-base font-bold leading-6 text-gray-600 max-md:mt-10 max-md:max-w-full">
                <div className="text-4xl font-medium leading-10 text-gray-900 max-md:max-w-full">
                  Learn & Earn Initiative
                </div>
                <div className="font-normal mt-12 max-md:mt-10 max-md:max-w-full">
                  Foster a culture of continuous learning, incentivizing
                  employees to contribute their expertise. Drive collaboration
                  and motivation by rewarding commissions for knowledge sharing,
                  nurturing a vibrant work culture.
                </div>
                <div className="mt-6 max-md:max-w-full font-normal">
                  Boost retention rates by providing transparent career
                  progression routes, enhancing employee contentment and
                  commitment.
                </div>
                <div className="mt-6">
                  {" "}
                  {/* Added margin-top here */}
                  <Button href="#contact" text="Get Started" />
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow justify-center max-md:mt-10 max-md:max-w-full">
                <img
                  loading="lazy"
                  srcSet={KeyfeatureImage}
                  className="w-full aspect-[1.37] max-md:max-w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default keyfeature2;

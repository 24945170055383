import React, { useState } from "react";
import Container from "./Container";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [submitMessage, setSubmitMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone number is invalid";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitMessage(""); // Reset the message
    if (validateForm()) {
      try {
        const response = await fetch(
          "https://mailapi.wiztap.in/api/mail/inquiry",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        if (response.ok) {
          setSubmitMessage("Form submitted successfully!");
          setFormData({ name: "", email: "", phone: "", message: "" });
        } else {
          setSubmitMessage("Failed to submit form. Please try again later.");
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        setSubmitMessage(
          "An unexpected error occurred. Please try again later."
        );
      }
    }
  };

  return (
    <section id="contact" className="py-10 bg-white">
      <Container>
        <div className="flex flex-col md:flex-row justify-between rounded-lg border border-gray-300 bg-gradient-to-b-10 bg-size-10">
          <div className="flex flex-col self-stretch py-12 pr-5 pl-14 rounded-lg max-w-[633px] max-md:pl-5">
            <div className="flex flex-col max-w-full w-[253px]">
              <div className="justify-center text-4xl font-medium leading-10 text-gray-800">
                Contact Us
              </div>
              <div className="mt-2 text-base leading-6 text-gray-600">
                Contact us for sales and support
              </div>
            </div>
            <div className="mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow justify-center pl-8 pr-20 py-5 w-full bg-white rounded-lg max-md:px-5 max-md:mt-6">
                    <div className="flex flex-col justify-center">
                      <div className="flex gap-4">
                        <div className="flex justify-center items-center p-1.5 bg-blue-600 rounded h-[37px] w-[37px]">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/218a392e092868222d246c0bf6fadaa9af16e7ca835b54e124d971f6d70766b3?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                            className="w-5 aspect-square stroke-[1.5px] stroke-white"
                          />
                        </div>
                        <div className="flex-1 text-xl font-bold leading-7 text-gray-900">
                          Sales
                        </div>
                      </div>
                      <div className="flex flex-col justify-center mt-4 text-base leading-6">
                        <div className="justify-center font-medium text-blue-600 whitespace-nowrap">
                          sales@WizTap.in
                        </div>
                        <div className="justify-center mt-2 text-gray-600">
                          +91 7899680920
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow justify-center pl-8 pr-20 py-5 w-full bg-white rounded-lg max-md:px-5 max-md:mt-6">
                    <div className="flex flex-col justify-center">
                      <div className="flex gap-4 justify-center">
                        <div className="flex justify-center items-center p-2 w-9 h-9 bg-blue-600 rounded">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/32094dcadb4b7d0173c466a3bac0d38ae855db542babf3935da5511dfcb25b82?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                            className="aspect-square stroke-[2px] stroke-gray-200 w-[18px]"
                          />
                        </div>
                        <div className="flex-1 justify-center my-auto text-xl font-bold leading-7 text-gray-900 whitespace-nowrap">
                          Support
                        </div>
                      </div>
                      <div className="flex flex-col justify-center mt-4 text-base leading-6">
                        <div className="justify-center font-medium text-blue-600 whitespace-nowrap">
                          support@wiztap.in
                        </div>
                        <div className="mt-2 text-gray-600">+91 7899680920</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:w-6/12 p-14 rounded-lg rounded-l-none">
            <div class="text-4xl max-w-3xl font-medium text-gray-800 mb-10 sm:text-2xl md:text-4xl">
              Get started today
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block">
                  Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`mt-1.5 bg-white rounded-md border shadow-sm h-[39px] w-full ${
                    errors.name ? "border-red-500" : "border-gray-300"
                  }`}
                />
                {errors.name && (
                  <span className="text-red-500">{errors.name}</span>
                )}
              </div>
              <div>
                <label className="block">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`mt-1.5 bg-white rounded-md border shadow-sm h-[39px] w-full ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  }`}
                />
                {errors.email && (
                  <span className="text-red-500">{errors.email}</span>
                )}
              </div>
              <div>
                <label className="block">
                  Phone number <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={`mt-1.5 bg-white rounded-md border shadow-sm h-[39px] w-full ${
                    errors.phone ? "border-red-500" : "border-gray-300"
                  }`}
                />
                {errors.phone && (
                  <span className="text-red-500">{errors.phone}</span>
                )}
              </div>
              <div>
                <label className="block">Message</label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="mt-1.5 bg-white rounded-md border shadow-sm h-[125px] w-full border-gray-300"
                />
              </div>
              <div className="space-y-3">
                <button
                  type="submit"
                  className="w-full py-3 bg-blue-600 text-white font-bold rounded-lg"
                >
                  Request a Demo
                </button>
                {submitMessage && (
                  <div className="text-center text-red-500">
                    {submitMessage}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactUs;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section id="#" className="bg-white">
      <div className="flex justify-center items-center px-4 md:px-8 lg:px-16 bg-white border border-t border-solid max-md:px-5">
        <div className="flex flex-col py-8 md:py-12 lg:py-16 w-full max-w-screen-xl">
          <div className="flex gap-0 pr-5 md:pr-10 lg:pr-20 text-base leading-6 text-gray-500 max-md:flex-wrap">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0c0bbc49b9af8ba1affed1ae21c9246eb78c3d0cafceb407904ceb31e3211697?"
              className="shrink-0 aspect-[1.92] w-[75px] mb-4 md:mb-0"
              alt="Logo"
            />
            <div className="flex justify-center items-center px-5 md:px-8 lg:px-16 my-auto max-md:px-0">
              <div className="flex gap-3 md:gap-3 text-center md:text-left">
                <a href="#features" className="whitespace-nowrap">
                  Features
                </a>
                <a href="#benefits" className="whitespace-nowrap">
                  Benefits
                </a>
                <a href="#faqs" className="whitespace-nowrap">
                  FAQ’s
                </a>
                <a href="#contact" className="whitespace-nowrap">
                  Contact us
                </a>
                <Link
                  to="/terms-and-conditions"
                  className="whitespace-nowrap"
                  target="_blank"
                >
                  T&C
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8 max-md:max-w-full">
            <div className="h-px bg-gray-200" />
            <div className="flex flex-col mt-4 md:flex-row md:items-center md:justify-between text-center md:text-left">
              <div className="text-base leading-6 text-gray-500 mb-4 md:mb-0">
                © 2024 iFocus Systec, Inc. All rights reserved.
              </div>
              <div className="flex gap-5 justify-center md:justify-start items-center">
                <a
                  href="https://www.linkedin.com/company/wiztap/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8f8e21801ddc56f34fcdd6b5fe98ad63736438a6c94467b8d3a24eb991122b12?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 w-[18px] aspect-square"
                    alt="LinkedIn"
                  />
                </a>
                <a
                  href="https://x.com/Wiztap_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d5033fd0b141fd725ca1b723ce2d9830e66d13628a498ec6dca25a7788647a60?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 w-[15px] aspect-[0.93]"
                    alt="Twitter"
                  />
                </a>
                <a
                  href="https://www.instagram.com/wiztap.in/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/449ba04be1d14b334c985823c6e822501d2d7cf038ca7b88fdd57312ba7e4a73?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 w-[18px] aspect-square"
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://www.youtube.com/watch?v=ZwUNYBN7Gxo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e81f1cebde265ac3c84fed6acc62b22f50f4371b0167a91d517948b053ea7f79?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 w-[23px] aspect-[1.43]"
                    alt="YouTube"
                  />
                </a>
                <a
                  href="https://www.facebook.com/people/Wiztap/61554643859751/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f774a2326834c7875785963f23186e52b6d002baa54bab0d000032c704657b9d?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                    className="shrink-0 w-6 aspect-square"
                    alt="Facebook"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Cta from "./components/Cta";
import FAQ from "./components/Faq";
import Footer from "./components/Footer";
import Highlights from "./components/Highlights";
import KeyFeatures from "./components/KeyFeatures";
import KeyFeatures2 from "./components/KeyFeatures2";
import Benefits from "./components/Benefits";
import ContactUs from "./components/ContactUs";

import TermsAndConditions from "./components/TermsAndConditions";

const Home = () => (
  <>
    <Header />
    <Hero />
    <KeyFeatures />
    <Benefits />
    <Highlights />
    <KeyFeatures2 />
    <Cta />
    <FAQ />
    <ContactUs />
    <Footer />
  </>
);

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

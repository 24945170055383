import React from "react";
import Container from "./Container";
import Button from "./Button";
import backgroundImage from "../Assets/Images/CTA_BG.png";
import ContactButton from "./ContactButton";

const Cta = () => {
  return (
    <section
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      id="features"
      className="py-8 bg-white"
    >
      <div className="relative z-auto flex mx-auto justify-center items-center px-6 py-10 md:px-16 md:py-14 text-base font-bold bg-white rounded-2xl border border-solid shadow-sm max-w-[917px]">
        <div className="flex flex-col w-full max-w-[657px]">
          <div className="font-medium px-3 text-4xl leading-10 text-center text-gray-900 md:px-6 md:text-5xl md:leading-12">
            Ready to Transform Your Workforce?
          </div>
          <div className="px-3 mt-6 leading-6 text-center text-gray-600 md:mt-9">
            Join the growing number of organizations who have elevated the
            potential of their employees with Wiztap. Sign up now to unlock the
            future of talent development.
          </div>
          <div className="flex flex-col md:flex-row self-center mt-6 leading-[150%] md:mt-9">
            <div className="flex justify-center px-5 py-2.5 text-white">
              <Button href="#contact" text="Get Started" />
            </div>
            <div className="flex justify-center px-5 py-2.5 text-blue-600 mt-4 md:mt-0 md:ml-4">
              <ContactButton href="#contact" text="Contact Us" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;

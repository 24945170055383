import React from "react";

const ContactButton = ({ href, text }) => {
  return (
    <a
      href={href}
      className="justify-center self-start px-4 py-2 border border-blue-600 text-base leading-6 text-blue-600 bg-white rounded-lg max-md:mt-3"
    >
      {text}
    </a>
  );
};

export default ContactButton;

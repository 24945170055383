import React from "react";
import Container from "./Container";
import Button from "./Button";
import HeroImage from "../Assets/Images/Hero-BG.png";

const Hero = () => {
  return (
    <>
      <section id="hero" className="py-6 bg-white">
        <Container>
          <div className="max-md:pr-5">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[40%] max-md:w-full max-md:ml-0">
                <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                  <div className="text-6xl font-bold text-black leading-[72px] max-md:text-4xl max-md:leading-10 max-md:text-left">
                    Transform Your Workforce with WizTap
                  </div>
                  <div className="flex flex-col mt-8 max-md:mt-5 max-md:text-left">
                    <div className="text-lg leading-7 text-gray-700 max-md:text-base max-md:leading-6 max-md:text-left">
                      Your go-to platform for skill measurement, skill
                      development, and talent matching, designed to accelerate
                      growth and productivity.
                    </div>
                    <div className="flex justify-start mt-12 text-base font-bold leading-6 text-white max-md:justify-start max-md:mt-8">
                      <div className="w-[130px]">
                        <Button href="#contact" text="Get Started" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[60%] max-md:w-full max-md:ml-0">
                <div className="flex grow justify-center max-md:mt-10">
                  <img
                    loading="lazy"
                    srcSet={HeroImage}
                    className="w-full h-auto max-md:w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      {/* Divider */}
      <hr className="mt-6 border-2 border-indigo-100" />
    </>
  );
};

export default Hero;

import React from "react";
import Container from "./Container";
import BenefitsImage from "../Assets/Images/BenefitsImage.png";

const Benefits = () => {
  return (
    <section id="benefits" className="py-16 bg-white">
      <Container>
        <div className="justify-between">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                <div className="justify-center text-5xl font-medium leading-12 text-gray-900 max-md:max-w-full max-md:text-4xl max-md:leading-10">
                  Expedite growth & unlock Limitless talent
                </div>
                <div className="justify-center mt-12 text-base leading-6 text-gray-600 max-md:mt-10 max-md:max-w-full">
                  With our diverse learning resources and streamlined talent
                  acquisition, your team is always ready to excel!
                </div>
                <div className="flex flex-col mt-12 max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-4 pr-6 max-md:flex-wrap max-md:pr-5">
                    <div className="flex justify-center items-center self-start p-3 rounded border border-solid">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/71732ac1218ac3b7828fb418617523a6d74afd9551fc94c336c9f381d9ee188e?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                        className="w-6 aspect-square"
                      />
                    </div>
                    <div className="flex flex-col max-md:max-w-full">
                      <div className="justify-center text-lg font-bold leading-7 text-gray-700 max-md:max-w-full">
                        Empower Through Upskilling
                      </div>
                      <div className="justify-center text-base leading-6 text-gray-500 max-md:max-w-full">
                        Advanced AI-driven skill assessments and personalized
                        learning paths enhance employee growth and
                        organizational success.
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 pt-4 pr-6 mt-4 border-t border-gray-100 border-solid max-md:flex-wrap max-md:pr-5">
                    <div className="flex justify-center items-center self-start p-3 rounded border border-solid">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6bfbda5ad16498740ae1e1290358826e70fd3ae41f00420fafb11b0c7f6dca16?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                        className="w-6 aspect-square"
                      />
                    </div>
                    <div className="flex flex-col max-md:max-w-full">
                      <div className="justify-center text-lg font-bold leading-7 text-gray-700 max-md:max-w-full">
                        Optimize Talent Management
                      </div>
                      <div className="justify-center text-base leading-6 text-gray-500 max-md:max-w-full">
                        Gain actionable insights, reduce hiring costs, and
                        maximize productivity with better talent allocation.
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 pt-4 pr-6 mt-4 border-t border-gray-100 border-solid max-md:flex-wrap max-md:pr-5">
                    <div className="flex justify-center items-center self-start p-3 rounded border border-solid">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ec9ff4034d5a25a06664b9797fcd8c07705858a24194b459c4e0fc28fda40e37?apiKey=d8e91bdc04594ed59f135bc4e59e9ebd&"
                        className="w-6 aspect-square"
                      />
                    </div>
                    <div className="flex flex-col max-md:max-w-full">
                      <div className="justify-center text-lg font-bold leading-7 text-gray-700 max-md:max-w-full">
                        Foster Collaboration and Rewards
                      </div>
                      <div className="justify-center text-base leading-6 text-gray-500 max-md:max-w-full">
                        Promote continuous learning and teamwork, with
                        incentives for employees to help peers upskill.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
              <div className="flex grow gap-5 justify-between px-5 py-3 w-full bg-white max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                <img
                  loading="lazy"
                  src={BenefitsImage}
                  className="w-full h-auto max-md:max-w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Benefits;

import React from "react";
import Container from "./Container";

const TermsAndConditions = () => {
  return (
    <>
      <section id="#" className=" bg-gray-100">
        <Container>
          <div className="py-20">
            <h1 className="mb-2 font-bold">TERMS OF USE</h1>
            <section className="mb-5">
              <p>
                To the fullest extent permitted by law, these Website Terms of
                Use constitute a legally binding contract between You (or Your)
                and Us. Your use of Our Website and all the content, text,
                graphics, statistics, photographs, information, logo, software
                and more ("Materials/Resources") as well as any products, tools
                or services that We make accessible or provide on Our Website
                are governed by these Terms and Conditions.
              </p>

              <p>
                The Terms are a legally binding contract between IFOCUS and you.
                If you use our website or any of the services provided on it,
                you agree to be legally bound by the Terms. Do not use the
                Website or any services offered if you do not agree to all of
                the Terms. You acknowledge and agree that by using the Website
                and any service provided, that you have read and you understand
                and agree to the Terms and this indicates your acceptance of,
                and agreement to be legally bound by the Terms.
              </p>

              <p>
                If you continue to use the Websites or services after the
                changes have been posted, you agree to the revised Terms, even
                if you haven't examined them. It is your responsibility to
                monitor the Websites for updates and changes to the Terms posted
                there.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">PRODUCT INFORMATION:</h2>
              <p>
                <b> Wiztap</b> is a product of Ifocus Systec (India) Pvt. Ltd.,
                and is a comprehensive skill development platform designed to
                empower users in their career advancement journey. It offers a
                range of features and services, including personalized learning
                paths, real-world projects, skill assessments, and talent
                matching. By using WizTap, users agree to adhere to the terms
                and conditions outlined by Ifocus Systec, including guidelines
                for usage, data privacy, and intellectual property rights.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">
                USING OUR WEBSITE WITHOUT AUTHORIZATION
              </h2>
              <p>
                Except as expressly provided above, all rights, titles, and
                interests are reserved. No other use of Our website is permitted
                without our written permission. Any other type of usage of Our
                website is strictly prohibited. Unauthorized use of our website
                and materials may result in an infringement of India's and other
                nations' intellectual property rights.
              </p>
              <p>
                We respect other people's intellectual property rights and ask
                that you do the same. We reserve the right to discontinue access
                to and use of Our Website in suitable circumstances and at our
                discretion.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">
                TERMINATING YOUR ACCESS TO OUR WEBSITE
              </h2>
              <p>
                If You violate any of these Website Terms of Usage, your use of
                Our Website will be promptly terminated. To be clear:
              </p>
              <ul>
                <li>
                  You have the right to stop using and accessing Our Website at
                  any time.
                </li>
                <li>
                  Unless we agree otherwise in writing, we reserve the right to
                  suspend, restrict, terminate, or limit Your access to or use
                  of part or all of Our Website at any point of time and for
                  whatever reason. You must promptly destroy any downloaded or
                  printed Materials, as well as all copies of such Materials, if
                  you are terminated for breach.
                </li>
              </ul>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">LIABILITY LIMITATION</h2>
              <p>
                We will not be accountable in any way in connection with Our
                Website or these: for any consequential, special, indirect,
                incidental, punitive or any other type of damage (including, but
                not limited to loss of income or business opportunities or data)
                that isn't expressly stated in the contract (including, but not
                limited to, personal data) or programs (including, but not
                limited to, the expense of program or data recovery or
                replacement); Any damage, loss or expenses incurred as a result
                of a disruption, delay, interruption, or inability to utilize
                any of Our services or products.
              </p>
              <p>
                However, even if we have been expressly notified of the
                likelihood of these kinds of damages, they may occur.
                Furthermore, nothing in this agreement excludes or limits any
                obligation that cannot be excluded or limited by relevant
                legislation.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">LAYOUT/ DESIGN</h2>
              <p>
                IFOCUS owns the layout and design of this website. Copies,
                duplicates, reproduction, retransmission, or imitations of any
                part of the Websites' content are strictly prohibited due to
                copyright protection, trade dress, and other regulations. You
                may not copy or transmit any logo, picture, sound, graphic, or
                image unless IFOCUS expressly permits it.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">OTHER AGREEMENTS</h2>
              <p>
                Other Agreements govern our obligations with respect to any
                services, products, or access that We make available to You;
                these Website Terms of Use shall not be construed to change the
                terms of any such Other Agreements. In the event of a conflict
                between these Website Terms of Use and any Other Agreement,
                then, the Other Agreement takes precedence.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">TRADEMARKS</h2>
              <p>
                Our name, logos, and all other trademarks we utilize, are our
                entities' registered trademarks. You are permitted to use Our
                word or mark in text to reference Us, Our services, and
                products, fairly and honestly, as long as they follow the
                guidelines listed below. Our logos can only be used by us or/and
                our partners and our licensees who have written agreements
                signed with us allowing logo use. Any other usage is not
                permitted with our logos, nor with our stylized marks, without
                our express written permission. Our trademarks may not be used
                by you in a way that is likely to confuse or mislead third
                parties, which includes concerning Your relationship with IFOCUS
                or any endorsement or sponsorship of Your firm or company, your
                services or products, or their origin. Any such usage is
                strictly illegal and punishable by law.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">LINKING TO IFOCUS WEBSITE</h2>
              <p>
                You may create links to Our Website from other websites for your
                convenience. If you provide anyone with a link to Our Website,
                you must comply with the following conditions and all applicable
                laws unless an express agreement indicates otherwise:
              </p>
              <ul>
                <li>You may link to Materials but not copy them.</li>
                <li>
                  You are not permitted to create a browser or border
                  environment around any Materials.
                </li>
                <li>
                  You agree not to imply that We either endorse or are
                  associated with any website which links to Our Website or any
                  services, products, or information offered via that website.
                </li>
                <li>
                  You are not permitted to misrepresent Your relationship with
                  Us.
                </li>
                <li>
                  You are not permitted to present inaccurate or misleading
                  information about Us and/or Our services or products.
                </li>
                <li>
                  The website which links to Our Website shall not contain
                  anything that could be regarded as objectionable,
                  controversial, offensive, distasteful, a violation of privacy
                  rights, unlawful, or overtly sexual.
                </li>
                <li>
                  Only content suitable for all age groups shall be included on
                  the website that links to Our Website.
                </li>
              </ul>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">ACCOUNTS, PASSWORDS & SECURITY</h2>
              <p>
                You may be required to register an account in order to take
                advantage of certain Site features or services. You are solely
                responsible for safeguarding the confidentiality of your account
                information, which includes your password, as well as for any
                and all the activity that occurs under your account as a result
                of your failure to do so. Whenever you become aware of
                unauthorized access to your password or account, or a security
                breach, you agree to immediately tell IFOCUS, so that we can put
                measures in place to prevent future occurrences. If someone else
                uses your IFOCUS ID, password, or account, you could be held
                liable for any losses incurred by IFOCUS or any other user or
                visitor to the site as a result of your failure to keep your
                account information safe and confidential.
              </p>
              <p>
                You may not use anybody else's IFOCUS account, ID, or password
                without the consent or express permission and approval of the
                owner of that ID, login information, account, or password, at
                any time. IFOCUS cannot and will not be held liable for any
                damage or loss caused by your inability to meet these
                obligations.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold">INDEMNIFICATION</h2>
              <p>
                You agree to these terms to indemnify, defend, and hold IFOCUS
                harmless from any and all claims or liabilities, losses,
                expenses, costs, damages, fees (including Attorney's reasonable
                fees) that such parties may incur as a result of or arising from
                a violation of these Website Terms of Use by You or anyone using
                Your account. Any matter entitled to indemnification by You is
                subject to Our exclusive defence and control, in which case you
                undertake to cooperate with Us in our defence of such claim.
              </p>
            </section>
            <p className="mb-4">
              We reserve the right to change these Website Terms of Service at
              any time. We reserve the right to amend these Website Terms of Use
              (and any other documents referenced in or linked to from these
              Website Terms of Use) at any time and without notice. We shall
              notify you of any significant changes to Our website by means of a
              notice that appears here. We reserve the right to modify, suspend,
              or discontinue Our Website and/or any products, programs,
              services, and other offerings provided through or in connection
              with Our Website at any time without notice to You, and if You
              object to any such changes, your only recourse shall be to stop
              using Our Website. When you continue to use Our Website after
              notice of any such changes, it indicates You acknowledge and agree
              to abide by the terms and conditions of any such modifications.
            </p>

            <section className="mb-5">
              <h2 className="mb-2 font-bold">DISCLAIMERS</h2>
              <p>
                BECAUSE OUR WEBSITE IS PROVIDED "AS-IS" AND "WITH ALL FAULTS,"
                THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF OUR WEBSITE
                RESTS WITH YOU. WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES,
                EXPRESS, IMPLIED, OR STATUTORY, WITH RESPECT TO OUR WEBSITE,
                INCLUDING BUT NOT LIMITED TO ANY IMPLIED OR STATUTORY WARRANTIES
                OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE,
                TITLE, AND NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS.
              </p>
              <p>
                WE DO NOT GUARANTEE AND CANNOT ENSURE THAT THE WEBSITE WILL BE
                ERROR-FREE OR FREE OF PROBLEMS. This means that we offer no
                guarantee that Our Website will meet Your needs or that it will
                be available without interruption, timely, secure, or
                error-free, or that any defects in Our Website will be remedied
                without limiting the generality of the foregoing. Neither the
                outcomes produced from using Our Website nor the accuracy or
                reliability of any information accessed through Our Website are
                guaranteed by us. Any advice or information, whether oral or
                written, obtained by You through our website or from us, or our
                directors, officers, agents, or employees, (collectively,
                "IFOCUS"), will not create any guarantee/warranty.
              </p>
            </section>
            <section className="mb-5">
              <h2 className="mb-2 font-bold"> FEEDBACK & INFORMATION</h2>
              <p>
                If you would like to contact us for feedback or information or
                for any reason, please send an email to support@wiztap.in. Any
                comments, feedback, data, or information you provide us on this
                website will be considered non-confidential. IFOCUS will have
                full access and use of this information.
              </p>
            </section>
          </div>
        </Container>
      </section>
    </>
  );
};

export default TermsAndConditions;

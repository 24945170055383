import React from "react";
import Button from "./Button";
import HighligtsImage from "../Assets/Images/HighligtsImage.png";
import Container from "./Container";

const Highlights = () => {
  return (
    <Container>
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            srcSet={HighligtsImage}
            className="grow w-full aspect-[1.3] max-md:mt-10 max-md:max-w-full"
          />
        </div>
        <div className="flex flex-col ml-5 w-5/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow justify-center py-16 text-base font-bold max-md:mt-10 max-md:max-w-full">
            <div className="justify-center font-medium text-4xl leading-10 text-gray-900 max-md:max-w-full">
              Maximize Talent Efficiency
            </div>
            <div className="flex flex-col mt-12 font-normal leading-6 text-gray-600 max-md:mt-10 max-md:max-w-full">
              <div className="max-md:max-w-full">
                Enhance team performance through skill tracking and progress
                monitoring. Anticipate market shifts by analyzing skill gaps and
                adapting strategies <br></br> swiftly.
              </div>
              <div className="mt-6 max-md:max-w-full">
                Maximize efficiency by nurturing existing talent and minimizing
                hiring costs. Align skills with roles to streamline project
                completion and drive <br></br>success.
              </div>
            </div>
            <div className="flex flex-col justify-center mt-12 max-w-full text-white leading-[150%] w-[130px] max-md:mt-10">
              <div>
                <Button href="#contact" text="Get Started" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Highlights;

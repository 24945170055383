import React, { useState } from "react";
import Container from "./Container";
import Button from "./Button"; // Import the Button component

const MenuIcon = ({ className }) => (
  <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" className={className}>
    <path
      d="M5 6h14"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#0000FF"
    />
    <path
      d="M5 12h14"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#0000FF"
    />
    <path
      d="M9 18h10"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#0000FF"
    />
  </svg>
);

const CrossIcon = ({ className }) => (
  <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" className={className}>
    <path
      d="M6 18L18 6M6 6l12 12"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#0000FF"
    />
  </svg>
);

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white py-3">
      <Container>
        <div className="flex justify-between items-center">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0c0bbc49b9af8ba1affed1ae21c9246eb78c3d0cafceb407904ceb31e3211697?"
            className="shrink-0 aspect-[2.04] w-[79px]"
            alt="Logo"
          />
          <div className="hidden md:flex gap-8 items-center">
            <a href="#features" className="hover:text-blue-600">
              Features
            </a>
            <a href="#benefits" className="hover:text-blue-600">
              Benefits
            </a>
            <a href="#faqs" className="hover:text-blue-600">
              FAQs
            </a>
            <a href="#contact" className="hover:text-blue-600">
              Contact us
            </a>
            <Button href="#contact" text="Get Started" />
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600 focus:outline-none"
            >
              {isMenuOpen ? (
                <CrossIcon className="h-6 w-6" />
              ) : (
                <MenuIcon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
        {isMenuOpen && (
          <div className="flex flex-col mt-4 space-y-2 md:hidden">
            <a href="#features" className="hover:text-blue-600">
              Features
            </a>
            <a href="#benefits" className="hover:text-blue-600">
              Benefits
            </a>
            <a href="#faqs" className="hover:text-blue-600">
              FAQs
            </a>
            <a href="#contact" className="hover:text-blue-600">
              Contact us
            </a>
            <Button href="#contact" text="Get Started" />
          </div>
        )}
      </Container>
    </header>
  );
};

export default Header;
